<template>
	<center>Conta gmail adicionada com sucesso</center>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { addGmailAuth } from "../api/gmail";

export default Vue.extend({
	name: "Gmail",
	created() {
		this.postGmailAuth();
	},
	computed: mapState(["user"]),
	methods: {
		...mapMutations(["login"]),
		async postGmailAuth() {
			let code = this.$route.fullPath.split("?code=")[1];
			code = code.split("&")[0];

			const response = await addGmailAuth(code);

			if (response.status === 201) {
				this.login({ user: { ...this.user, gmailRefreshToken: true } });

				this.$router.push("/invoices");
			}
		},
	},
});
</script>
